<template>


  <div class="d-flex flex-column flex-root">


    <div
            class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
            id="kt_login"
    >


      <div
              class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <div class="text-center mb-5 mb-lg-5">
              <h3 class="font-size-h1">Sign In</h3>
              <p class="text-muted font-weight-semi-bold">
                Enter your email and password
              </p>
            </div>

            <!--            <div role="alert" class="alert alert-info">-->
            <!--                <div class="alert-text">-->
            <!--                  Use account <strong>admin@demo.com</strong> and password-->
            <!--                  <strong>demo</strong> to continue.-->
            <!--                </div>-->
            <!--              </div>-->

            <!--begin::Multi Authenticator Form-->
            <b-form class="kt-form" v-if="multiAuthenticator">
              <div
                      role="alert"
                      class="alert alert-danger"
                      v-if="errors.length > 0"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>

              <b-form-group
                      id="example-input-group-1"
                      label
                      label-for="example-input-1"
                      v-if="!verificationEmailBtn"
              >
                <b-form-input
                        class="form-control form-control-solid h-auto py-5 px-6"
                        id="example-input-1"
                        name="example-input-1"
                        @keypress.enter.native.prevent
                        placeholder="Google Verification Code"
                        v-model="authenticator.google.verification_code"
                        aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                >Verification Code
                </b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                      label
                      label-for="email-verification-code"
                      v-if="verificationEmailBtn"
              >
                <b-form-input
                        class="form-control form-control-solid h-auto py-5 px-6"
                        name="email-verification-code"
                        @keypress.enter.native.prevent
                        placeholder="Email Verification Code"
                        v-model="authenticator.email.verification_code"
                        aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                >Email Verification Code
                </b-form-invalid-feedback
                >
              </b-form-group>

              <!--begin::Divider-->
              <div class="kt-login__divider">
                <div class="kt-divider">
                  <span></span>
                  <span
                          class="kt-link kt-login__signup-link"
                          @click="verificationEmailBtn = !verificationEmailBtn"
                  >Try Alternative</span
                  >
                  <span></span>
                </div>
              </div>

              <div class="kt-login__actions">
                <span
                        class="kt-login__signup-label"
                        v-if="!verificationEmailBtn"
                ></span>
                <b-button
                        v-if="verificationEmailBtn"
                        type="button"
                        @click="requestEmailVerificationCode"
                        class="btn btn-warning btn-elevate"
                        :disabled="emailVerificationMailRequested"
                >
                  {{
                  emailVerificationMailRequested
                  ? "You can request again after 1 minute"
                  : "Request Email Verification"
                  }}
                </b-button>

                <b-button
                        type="button"
                        @click="verifyMultiAuthCode"
                        id="verify_btn"
                        ref="kt_login_signin_submit"
                        class="btn btn-elevate btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
                >Verify
                </b-button
                >
              </div>
              <!--end::Action-->
            </b-form>
            <!--end::Multi Authenticator Form-->

            <!--begin::Google Verification Form-->
            <b-form
                    class="kt-form"
                    @submit.stop.prevent="verifyGoogleAuthenticator"
                    v-if="googleAuthenticator"
            >
              <div
                      role="alert"
                      class="alert alert-danger"
                      v-if="errors.length > 0"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>

              <b-form-group
                      id="example-input-group-1"
                      label
                      label-for="example-input-1"
              >
                <b-form-input
                        class="form-control form-control-solid h-auto py-5 px-6"
                        id="example-input-1"
                        name="example-input-1"
                        placeholder="Verification Code"
                        v-model="authenticator.google.verification_code"
                        aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                >Verification Code
                </b-form-invalid-feedback
                >
              </b-form-group>

              <div class="kt-login__actions">
                <span class="kt-login__signup-label"></span>
                <b-button
                        type="submit"
                        id="verify_btn"
                        ref="kt_login_signin_submit"
                        class="btn btn-elevate btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
                >Verify
                </b-button
                >
              </div>
              <!--end::Action-->
            </b-form>
            <!--end::Google Verification Form-->

            <!--begin::Email Verification Form-->
            <b-form class="kt-form" v-if="emailAuthenticator">
              <div
                      role="alert"
                      class="alert alert-danger"
                      v-if="errors.length > 0"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>

              <b-form-group
                      id="example-input-group-1"
                      label
                      label-for="example-input-1"
              >
                <b-form-input
                        class="form-control form-control-solid h-auto py-5 px-6"
                        id="example-input-1"
                        name="example-input-1"
                        placeholder="Email Verification Code"
                        v-model="authenticator.email.verification_code"
                        aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                >Verification Code
                </b-form-invalid-feedback
                >
              </b-form-group>

              <div class="kt-login__actions">
                <span class="kt-login__signup-label"></span>
                <b-button
                        type="button"
                        @click="verifyEmailAuthenticator"
                        id="verify_btn"
                        ref="kt_login_signin_submit"
                        class="btn btn-elevate btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
                >Verify
                </b-button
                >
              </div>
              <!--end::Action-->
            </b-form>
            <!--end::Email  Verification Form-->
            <!-- Main Login -->
            <b-form
                    class="kt-form"
                    @submit.stop.prevent="onSubmit"
                    v-if="
                !googleAuthenticator &&
                !emailAuthenticator &&
                !multiAuthenticator
              "
            >
              <div
                      role="alert"
                      class="alert alert-danger"
                      v-if="errors.length > 0"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>

              <b-form-group
                      id="example-input-group-1"
                      label
                      label-for="example-input-1"
              >
                <b-form-input
                        class="form-control form-control-solid h-auto py-5 px-6"
                        id="example-input-1"
                        name="example-input-1"
                        placeholder="Your email or CRN no"
                        v-model="$v.form.email.$model"
                        :state="validateState('email')"
                        aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback">
                  Email or CRN no. required and must be valid.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                      id="example-input-group-2"
                      label
                      label-for="example-input-2"
              >
                <b-form-input
                        class="form-control form-control-solid h-auto py-5 px-6"
                        type="password"
                        id="example-input-2"
                        name="example-input-2"
                        placeholder="Your Password"
                        v-model="$v.form.password.$model"
                        :state="validateState('password')"
                        aria-describedby="input-2-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-2-live-feedback"
                >This information is required
                </b-form-invalid-feedback
                >
              </b-form-group>

              <router-link
                      v-if="accountNotVerified"
                      class="btn btn-primary text-white mt-3 col-md-12"
                      :to="{ name: 'verification' }"
              >Verify your account
              </router-link
              >


              <div
                      class="form-group d-flex flex-wrap justify-content-between align-items-center"
              >


                <router-link
                        href="#"
                        class="text-dark-60 text-hover-primary my-3 mr-2"
                        id="kt_login_forgot"
                        :to="{ name: 'forgot-password' }"
                >{{ $t("AUTH.FORGOT.TITLE") }}
                </router-link
                >
                <button
                        type="submit"
                        id="login_btn"
                        ref="kt_login_signin_submit"
                        class="btn btn-elevate btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
                >
                  {{ $t("AUTH.LOGIN.BUTTON") }}
                </button>


              </div>
            </b-form>

            <vue-recaptcha
                    v-if="sitekey"
                    ref="invisibleRecaptcha"
                    :sitekey="sitekey"
                    @verify="onCaptchaVerified"
                    :loadRecaptchaScript="true"
                    size="invisible"
            ></vue-recaptcha>
          </div>
          <!--end::Signin-->
        </div>
      </div>


      <!--begin::Content header-->
<!--      <div-->
<!--              class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"-->
<!--      >-->
<!--      <span class="font-weight-bold font-size-3 text-dark-60">-->
<!--        Don't have an account?-->
<!--      </span>-->
<!--        <router-link-->
<!--                class="font-weight-bold font-size-3 ml-2"-->
<!--                :to="{ name: 'register' }"-->
<!--        >-->
<!--          Sign Up!-->
<!--        </router-link>-->
<!--      </div>-->
      <!--end::Content header-->
    </div>


    <vue-snotify></vue-snotify>
  </div>

</template>

<style lang="scss" scoped>
  .spinner.spinner-right {
    padding-right: 3.5rem !important;
  }
</style>

<script>
  import {mapState} from "vuex";
  import {LOGIN} from "@/core/services/store/auth.module";
  import VueRecaptcha from "vue-recaptcha";
  import {validationMixin} from "vuelidate";
  import {email, minLength, required} from "vuelidate/lib/validators";
  import UserService from "@/core/services/user/UserService";
  import SiteSettingService from "@/core/services/site-setting/SiteSettingService";

  const userService = new UserService();
  const siteSettingService = new SiteSettingService();
  export default {
    components: {VueRecaptcha},
    mixins: [validationMixin],
    name: "login",
    data() {
      return {
        errors: [],
        accountNotVerified: false,
        googleAuthenticator: false,
        emailAuthenticator: false,
        multiAuthenticator: false,
        verificationEmailBtn: false,
        emailVerificationMailRequested: false,
        site_main_url: process.env.VUE_APP_SERVER_URL,
        // sitekey: localStorage.getItem('sk'),
        setting: null,
        authenticator: {
          google: {
            verification_code: "",
          },
          email: {
            verification_code: "",
          },
        },
        // Remove this dummy login info
        form: {
          email: "",
          password: "",
        },
      };
    },
    validations: {
      form: {
        email: {
          required,
        },
        password: {
          required,
          minLength: minLength(3),
        },
      },
    },
    mounted() {
    },
    methods: {
      redirectIfLoggedIn() {
        if (this.$store.getters.isAuthenticated) {
          this.$router.push({name: "dashboard"});
        }
      },
      validateState(name) {
        const {$dirty, $error} = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      resetForm() {
        this.form = {
          email: null,
          password: null,
        };

        this.$nextTick(() => {
          this.$v.$reset();
        });
      },
      activateSubmitSpinner(btnId) {
        const submitButton = document.getElementById(btnId);
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      },

      deactivateSubmitSpinner(btnId, seconds) {
        const submitButton = document.getElementById(btnId);
        setTimeout(() => {
          submitButton.classList.remove("kt-spinner", "spinner", "spinner-light", "spinner-right");
        }, seconds * 1000);
      },

      setLogin(data) {
        this.$store
                .dispatch(LOGIN, data)
                // go to which page after successfully login
                .then(() => {
                  this.$router.push({name: "dashboard"});
                });
      },
      onSubmit() {
        this.$refs.invisibleRecaptcha.execute();
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      },

      onCaptchaVerified: function (recaptchaToken) {
        if (!this.$v.form.$anyError) {
          this.activateSubmitSpinner("login_btn");
          // set spinner to submit button
          userService.checkVerificationEnabled(this.form)
                  .then((res) => {
                    this.errors = [];
                    this.accountNotVerified = false;
                    this.deactivateSubmitSpinner("login_btn", 1);
                    if (res.data.status == "NOT_FOUND") {
                      this.$snotify.error(res.data.errors);
                      this.deactivateSubmitSpinner("login_btn", 1);
                    } else if (res.data.status == "NOT_VERIFIED") {
                      this.$snotify.error(res.data.errors);
                      this.accountNotVerified = true;
                      this.deactivateSubmitSpinner("login_btn", 1);
                    } else if (
                            res.data.data.is_google_two_factor_authentication_enabled &&
                            res.data.data.is_email_authentication_enabled
                    ) {
                      this.multiAuthenticator = true;
                    } else if (
                            res.data.data.is_google_two_factor_authentication_enabled
                    ) {
                      this.googleAuthenticator = true;
                    } else if (res.data.data.is_email_authentication_enabled) {
                      this.emailAuthenticator = true;
                    } else {
                      this.setLogin(this.form);
                    }
                  })
                  .catch((response) => {
                  });
        }
      },

      verifyMultiAuthCode() {
        if (this.verificationEmailBtn) this.verifyEmailAuthenticator();
        else this.verifyGoogleAuthenticator();
      },
      verifyGoogleAuthenticator() {
        if (this.authenticator.google.verification_code == "") {
          this.errors = [];
          this.$snotify.error("Please enter a verification code.");
        } else {
          this.errors = [];
          let data = this.form;
          data.verification_code = this.authenticator.google.verification_code;
          userService.verifyGoogleVerificationCode(data)
                  .then((res) => {
                    this.errors = [];
                    this.authenticator.google.verification_code = "";
                    this.deactivateSubmitSpinner("verify_btn", 1);
                    this.googleAuthenticator = false;
                    this.setLogin(this.form);
                  })
                  .catch(({response}) => {
                    this.$snotify.error(response.data.errors);
                    this.deactivateSubmitSpinner("verify_btn", 1);
                  });
        }

        setTimeout(() => {
          this.errors = [];
        }, 2000);
      },

      verifyEmailAuthenticator() {
        if (this.authenticator.email.verification_code == "") {
          this.errors = [];
          this.$snotify.error("Please enter a verification code.");
        } else {
          this.errors = [];
          let data = this.form;
          data.verification_code = this.authenticator.email.verification_code;
          userService.verifyEmailVerificationCode(data)
                  .then((res) => {
                    this.errors = [];
                    this.authenticator.email.verification_code = "";
                    this.setLogin(this.form);
                  })
                  .catch(({response}) => {
                    this.errors = response.data.errors;
                    this.deactivateSubmitSpinner("verify_btn", 1);
                  });
        }

        setTimeout(() => {
          this.errors = [];
        }, 2000);
      },

      requestEmailVerificationCode() {
        let data = this.form;
        userService.requesEmailVerificationCode(data)
                .then((res) => {
                  this.errors = [];
                  this.emailVerificationMailRequested = true;
                })
                .catch(({response}) => {
                  this.emailVerificationMailRequested = false;
                  this.errors = response.data.errors;
                });
        setTimeout(() => {
          this.emailVerificationMailRequested = false;
        }, 60000);
      },
    },
    computed: {
      ...mapState({}),

      backgroundImage() {
        return (
                process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
        );
      },

      sitekey :function ()
      {
        return  this.$store.getters.siteKey;
      }
    },
  };
</script>
